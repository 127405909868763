import { ChangeEvent, MouseEvent, memo } from 'react'
import { Box, Button, Stack, Typography, Container, Switch, Tooltip } from '@mui/material'
import { TokenInfo } from '@renec-foundation/rpl-token-registry'
import { useRouter } from 'next/router'
import type { SwapTokenInfo } from '@/hooks/use-tokens'

import BaseAmountInput from '@/components/base/BaseAmountInput'
import TokenPicker from '@/components/base/TokenPicker'
import useTrans from '@/hooks/useTrans'
import Rate from './components/rate'
import Fee from './components/fee'
import Information from './components/information'
import { format } from '@/utils/helpers.number'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useFeatureFlags } from '@/hooks/use-feature-flags'

type TransferInputPageProps = {
  isLanding?: boolean
  tokenIn: SwapTokenInfo
  tokenOut: SwapTokenInfo
  inputAmount: string
  outputAmount: string
  isLoading: boolean
  isGaslessModeEnabled: boolean
  handleInputAmountChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOutputAmountChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSwitchMintAddresses: (e: MouseEvent<HTMLButtonElement>) => void
  handleRefreshRateClick: (e: MouseEvent<HTMLButtonElement>) => void
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => void
  disabledSubmit: boolean
  setTextInputRef: (element: HTMLInputElement) => void
  customTokens: TokenInfo[]
  exchangeRate: string
  displayExchangeRate: string
  error: string | null
  discountAmount: number
  discountWirePoints: number
  toggleApplyDiscount: () => void
  appliedDiscount: boolean
  discountTier: number
}

const View = (props: TransferInputPageProps) => {
  const {
    isLanding,
    tokenIn,
    tokenOut,
    inputAmount,
    outputAmount,
    isLoading,
    handleInputAmountChange,
    handleOutputAmountChange,
    handleSwitchMintAddresses,
    handleRefreshRateClick,
    onSubmit,
    disabledSubmit,
    setTextInputRef,
    customTokens,
    displayExchangeRate,
    error,
    discountAmount,
    discountWirePoints,
    toggleApplyDiscount,
    appliedDiscount,
    discountTier,
  } = props
  const trans = useTrans()
  const router = useRouter()
  const { data: featureFlags } = useFeatureFlags()

  if (!tokenIn || !tokenOut) return null

  return (
    <Container
      maxWidth="sm"
      className={'mt-10 sm:mt-20 md:max-w-[580px]'}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className="p-6 rounded-xl shadow-xl bg-white"
      >
        <Stack className="w-full" direction="column">
          <Typography className="text-[#2C2E31]" variant="body1" fontWeight="bold">
            {trans.home.you_send}
          </Typography>
          <Box
            data-testid="input-wrapper"
            className="w-full border-2 rounded-2xl pt-3 px-3 pb-3 mt-2"
          >
            <Stack direction="row">
              <TokenPicker
                data-testid="token-picker-a"
                nickName="A"
                customTokens={customTokens}
                hideSelected={true}
              />
              <Stack className="flex basis-full" justifyContent="center" alignItems="end">
                <BaseAmountInput
                  data-testid="input-amount-input"
                  id="input-amount-input"
                  inputRef={setTextInputRef}
                  value={inputAmount || 0}
                  onChange={handleInputAmountChange}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <Rate
          tokenIn={tokenIn}
          tokenOut={tokenOut}
          exchangeRate={displayExchangeRate}
          isLoadingRate={isLoading}
          handleSwitchMintAddresses={handleSwitchMintAddresses}
          handleRefreshRateClick={handleRefreshRateClick}
        />

        <Stack className="w-full mt-5" direction="column">
          <Typography className="text-[#2C2E31]" variant="body1" fontWeight="bold">
            {trans.home.recipient_receive}
          </Typography>
          <Box
            data-testid="output-wrapper"
            className="w-full border-2 rounded-2xl pt-3 px-3 pb-3 mt-2"
          >
            <Stack direction="row">
              <TokenPicker
                data-testid="token-picker-b"
                nickName="B"
                customTokens={customTokens}
                hideSelected={true}
              />
              <Stack className="flex basis-full" justifyContent="center" alignItems="end">
                <BaseAmountInput
                  id="output-amount-input"
                  data-testid="output-amount-input"
                  decimals={tokenOut.decimals}
                  value={outputAmount || 0}
                  onChange={handleOutputAmountChange}
                  readOnly
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>

        {featureFlags?.instantwire_discount_feature && discountWirePoints > 0 &&
          <Stack alignItems="center" className="w-full" direction="row" spacing={2}>
            <Switch className="my-5"
              onClick={toggleApplyDiscount}
              checked={appliedDiscount}
            />
            <Typography>
              {trans.discount.msg(discountWirePoints)} (~ {format(discountAmount, true, tokenIn.decimals)} {tokenIn.name})
            </Typography>
            <Tooltip
              title={
                <Typography className="text-sm" dangerouslySetInnerHTML={{
                  __html: trans.discount.tooltip[discountTier][tokenIn.name],
                }} />
              }
              componentsProps={{
                tooltip: { className: 'bg-iw-neutral-black p-2' },
              }}
              placement="top">
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
        }

        <Typography className="pt-2" color="red">
          {error}
        </Typography>

        <Fee
          discountAmount={discountAmount}
          appliedDiscount={appliedDiscount}
          tokenIn={tokenIn}
          inputAmount={inputAmount}
        />

        <Information isShowNoFee={!!isLanding} />

        <Stack className="w-full pt-4" spacing={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={disabledSubmit}
            className="hover:border-none border-none relative h-[60px] font-bold text-lg"
          >
            {trans.transaction_input.continue}
          </Button>
          {!isLanding && (
            <Button
              fullWidth
              className="bg-transparent hover:bg-transparent hover:underline text-[#000] font-semibold text-lg"
              onClick={() => router.push('/landing')}
            >
              {trans.transaction_input.cancel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

const TransferInputWithMemo = memo(View)
TransferInputWithMemo.displayName = 'TransferInputView'
export default TransferInputWithMemo
