import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useCallback, useContext, useRef } from 'react'
import { availablePairsOfCurrencies, customTokens } from '@/constants/token'
import { useEventCallback } from '@/root/src/utils/use-event-callback'
import { exchangeRates } from '@/root/src/constants/exchange_rates'
import { Transaction } from '@/model/transaction'
import { track } from '@/utils/amplitude'
import {
  MAXIMUM_AMOUNT_IN_USD,
  MAXIMUM_AMOUNT_IN_VND,
  MINIMUM_AMOUNT_IN_USD,
  MINIMUM_AMOUNT_IN_VND,
} from '@/constants/transfer'

import useTrans from '@/root/src/hooks/useTrans'
import SwapContext from '@/contexts/swap'
import View from './view'
import useCalculateDiscountAmount from '@/hooks/use-calculate-discount-amount'

type Props = {
  isLanding?: boolean
  handleSubmit: (transaction: Transaction) => void
  enteredTransaction?: Transaction
}

const TransferInputPage = (props: Props) => {
  const { isLanding, handleSubmit, enteredTransaction } = props

  const {
    tokenIn,
    tokenOut,
    setMintAddresses,
    isLoading,
    inputAmount,
    outputAmount,
    setInputAmount,
    setOutputAmount,
    exchangeRate,
    displayExchangeRate,
    fee,
    feeBeforeDiscount,
    outputAmountInCurrencyIn,
    switchMintAddresses,
    refreshRate,
  } = useContext(SwapContext)

  const { discountAmount, discountWirePoints, discountTier } = useCalculateDiscountAmount({
    amount: inputAmount,
    currency: tokenIn.name,
  })

  const [appliedDiscount, setAppliedDiscount] = useState(false)

  // update data entered from landing page
  useEffect(() => {
    if (!enteredTransaction) return

    setMintAddresses([enteredTransaction.tokenIn.address, enteredTransaction.tokenOut.address])
    setInputAmount(enteredTransaction.amount.toString())
    setAppliedDiscount((enteredTransaction.discountAmount || 0) > 0)
  }, [
    enteredTransaction,
    setMintAddresses,
    setInputAmount,
    setAppliedDiscount,
  ])

  const transaction = useMemo(
    () =>
    ({
      amount: inputAmount,
      outputAmount,
      rate: exchangeRate,
      displayExchangeRate,
      currencyIn: tokenIn.name,
      currencyOut: tokenOut.name,
      createdAt: new Date(),
      tokenIn,
      tokenOut,
      fee,
      feeBeforeDiscount,
      status: 'unsaved',
      outputAmountInCurrencyIn,
      discountAmount: appliedDiscount ? discountAmount : 0,
      discountWirePoints: discountWirePoints,
    } as Transaction),
    [
      inputAmount,
      outputAmount,
      exchangeRate,
      displayExchangeRate,
      tokenIn,
      tokenOut,
      fee,
      feeBeforeDiscount,
      outputAmountInCurrencyIn,
      discountAmount,
      discountWirePoints,
      appliedDiscount,
    ],
  )

  const [error, setError] = useState<string | null>(null)
  const trans = useTrans()

  useEffect(() => {
    if (discountAmount === 0) setAppliedDiscount(false)
  }, [discountAmount])

  const toggleApplyDiscount = useCallback(() => {
    setAppliedDiscount(!appliedDiscount)
  }, [setAppliedDiscount, appliedDiscount])

  const handleRefreshRateClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      refreshRate()
    },
    [refreshRate],
  )

  const handleSwitchMintAddresses = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setError(null)
      switchMintAddresses()
    },
    [switchMintAddresses],
  )

  const handleInputAmountChange = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputAmount(value)
    setError(null)
  })

  const handleOutputAmountChange = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOutputAmount(e.target.value)
  })

  const validateMaximumAmount = useCallback(() => {
    if (transaction.currencyIn === 'VND') {
      if (parseFloat(inputAmount) < MINIMUM_AMOUNT_IN_VND) {
        setError(
          trans.instantwire_transaction.draft_transaction.lowest_input_amount(
            MINIMUM_AMOUNT_IN_VND,
            transaction.currencyIn,
          ),
        )
        return false
      }

      if (parseFloat(inputAmount) > MAXIMUM_AMOUNT_IN_VND) {
        setError(
          trans.instantwire_transaction.draft_transaction.exceed_input_amount(
            MAXIMUM_AMOUNT_IN_VND,
            transaction.currencyIn,
          ),
        )
        return false
      }
    }

    if (transaction.currencyIn === 'USD') {
      if (parseFloat(inputAmount) < MINIMUM_AMOUNT_IN_USD) {
        setError(
          trans.instantwire_transaction.draft_transaction.lowest_input_amount(
            MINIMUM_AMOUNT_IN_USD,
            transaction.currencyIn,
          ),
        )
        return false
      }
      if (parseFloat(inputAmount) > MAXIMUM_AMOUNT_IN_USD) {
        setError(
          trans.instantwire_transaction.draft_transaction.exceed_input_amount(
            MAXIMUM_AMOUNT_IN_USD,
            transaction.currencyIn,
          ),
        )
        return false
      }
    }

    const convertToUsdRate = exchangeRates[`USD${transaction.currencyIn}`]
    const exceedLimit = parseFloat(inputAmount) / convertToUsdRate > MAXIMUM_AMOUNT_IN_USD
    if (exceedLimit) {
      setError(
        trans.instantwire_transaction.draft_transaction.exceed_input_amount(
          MAXIMUM_AMOUNT_IN_USD,
          'USD',
        ),
      )
      return false
    }

    return true
  }, [trans, inputAmount, transaction])

  const validateEnabledTransferCurrencies = useCallback(() => {
    if (!availablePairsOfCurrencies.includes(`${tokenIn.name}${tokenOut.name}`)) {
      setError(trans.instantwire_transaction.draft_transaction.unavailable_pair(
        tokenIn.name, tokenOut.name,
      ))
      return false
    }
    return true
  }, [tokenIn, tokenOut])

  const onSubmit = useCallback(
    (_e: MouseEvent<HTMLButtonElement>) => {
      if (transaction.amount === '' || Number(transaction.amount) === 0)
        return setError(trans.instantwire_transaction.draft_transaction.empty_input_amount)

      if (!validateMaximumAmount()) return
      if (!validateEnabledTransferCurrencies()) return

      track('track_instantwire_currency_pair', { pair: `${tokenIn.name}_${tokenOut.name}` })
      handleSubmit(transaction)
    },
    [trans, tokenIn, tokenOut, inputAmount, transaction, validateMaximumAmount, validateEnabledTransferCurrencies],
  )

  const inputRef = useRef<HTMLInputElement>()

  const setTextInputRef = useCallback((element: HTMLInputElement) => {
    inputRef.current = element
  }, [])

  const disabledSubmit = useMemo(() =>
    transaction.amount === '' || Number(transaction.amount) === 0 ||
    Number(transaction.outputAmount) === 0 || error !== null || isLoading
    , [transaction.amount, transaction.outputAmount, isLoading])

  const computedProps = {
    isLanding,
    tokenIn,
    tokenOut,
    inputAmount: transaction.amount.toString(),
    isLoading: isLoading,
    outputAmount,
    isGaslessModeEnabled: false,
    handleInputAmountChange,
    handleOutputAmountChange,
    handleSwitchMintAddresses,
    handleRefreshRateClick,
    onSubmit,
    disabledSubmit,
    setTextInputRef,
    customTokens,
    exchangeRate,
    displayExchangeRate,
    error,
    discountAmount,
    discountWirePoints,
    toggleApplyDiscount,
    appliedDiscount,
    discountTier,
  }

  return <View {...computedProps} />
}

TransferInputPage.displayName = 'TransferInputContainer'
export default TransferInputPage
