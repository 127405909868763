import { Stack, Typography } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import type { SwapTokenInfo } from '@/hooks/use-tokens'

import useTrans from '@/hooks/useTrans'
import { format } from '@/root/src/utils/helpers.number'

interface FeeProps {
  tokenIn: SwapTokenInfo
  inputAmount: string
  discountAmount: number
  appliedDiscount: boolean
}

function Fee(props: FeeProps) {
  const { tokenIn, inputAmount, discountAmount, appliedDiscount } = props
  const trans = useTrans()
  const payAmount = format(
    Number(inputAmount) - (appliedDiscount ? discountAmount : 0),
    true,
    tokenIn.decimals,
  )

  return (
    <Stack className="w-full mb-4 mt-2 text-iw-dark-gray" spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{trans.transaction_input.fee}</Typography>
        <Typography className="text-iw-neutral-black font-semibold">{`0.00 ${tokenIn.name}`}</Typography>
      </Stack>
      {appliedDiscount &&
        <Stack direction="row" justifyContent="space-between">
          <Typography>{trans.discount.field}</Typography>
          <Typography className="text-iw-neutral-black font-semibold" data-testid="discount-amount">
            - {`${format(discountAmount, true, tokenIn.decimals)} ${tokenIn.name}`}
          </Typography>
        </Stack>
      }
      <Stack direction="row" justifyContent="space-between">
        <Typography>{trans.transaction_input.total_to_pay}</Typography>
        <Typography className="text-iw-neutral-black font-semibold" data-testid="pay-amount">
          <NumericFormat value={payAmount} thousandSeparator displayType="text" />
          {` ${tokenIn.name}`}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Fee
