import { Stack, Typography } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'

interface InformationProps {
  isShowNoFee: boolean
}

function Information(props: InformationProps) {
  const { isShowNoFee } = props
  const trans = useTrans()

  return (
    <Stack className="my-4 w-full" gap={1}>
      {isShowNoFee && (
        <Stack direction="row" spacing={1}>
          <Image src={staticImportIcon(IconName.NO_TRANSFER_FEE)} alt={IconName.NO_TRANSFER_FEE} />
          <Typography className="text-iw-neutral-black flex-1">
            {trans.landing.hero.no_fee}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" spacing={1}>
        <Image src={staticImportIcon(IconName.CLOCK)} alt={IconName.CLOCK} />
        <Typography
          className="text-iw-neutral-black flex-1"
          dangerouslySetInnerHTML={{ __html: trans.landing.hero.arrive }}
        />
      </Stack>
    </Stack>
  )
}

export default Information
