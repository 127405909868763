import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Image from 'next/image'

import SwapContext from '@/contexts/swap'
import { type SwapTokenInfo, useTokens } from '@/hooks/use-tokens'
import useTrans from '@/hooks/useTrans'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Autocomplete, {
  autocompleteClasses,
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete'
import Box, { type BoxProps } from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import InputBase from '@mui/material/InputBase'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { TokenInfo } from '@renec-foundation/rpl-token-registry'

interface PopperComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl?: any
  disablePortal?: boolean
  open: boolean
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#fff',
    padding: '4px 8px 8px',
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '8px',
      borderRadius: 8,
      marginTop: '4px',
      '&[aria-selected="true"]': {
        backgroundColor: '#E9EAEC',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          //backgroundColor: theme.palette.action.hover,
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(149, 157, 165, 0.6)'
  }`,
  borderRadius: 4,
  maxWidth: 160,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#E9EAEC',
  color: theme.palette.mode === 'light' ? '#29292E' : '#29292E',
}))

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 12,
  width: '100%',
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#fff',
    color: theme.palette.mode === 'light' ? '#29292E' : '#29292E',
    fontWeight: 600,
    padding: '4px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : theme.palette.primary.main
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : theme.palette.primary.main,
    },
  },
}))

export type TokenPickerProps = {
  'data-testid'?: string
  nickName: string
  disableDropdown?: boolean
  boxProps?: BoxProps
  allowCustomMintAddress?: boolean
  customToken?: SwapTokenInfo
  setCustomToken?: (token: SwapTokenInfo) => void
  blackList?: string[]
  hideSelected?: boolean
  disableFallbackToSwapToken?: boolean
  customTokens?: TokenInfo[]
}

/** Other tokens will have an order default is zero.
 *  The higher value will be put to the top of the list */
const tokenOrder = new Proxy(
  {
    reUSD: 2.1,
    reVND: 2,
    reCYN: 1.9,
    reKRW: 1.9,
    reJPY: 1.9,
    rePHP: 1.9,
    reEUR: 1.9,
    reGBP: 1.9,
  } as Record<string, number>,
  {
    get(target, prop: string) {
      return target[prop] || 0
    },
  },
)

const TokenPicker = (props: TokenPickerProps) => {
  const {
    nickName,
    disableDropdown,
    allowCustomMintAddress,
    customToken,
    setCustomToken,
    blackList,
    hideSelected,
    disableFallbackToSwapToken,
    customTokens,
  } = props

  const {
    switchMintAddresses,
    setInputMintAddress,
    setOutputMintAddress,
    tokenIn,
    tokenOut,
  } = useContext(SwapContext)
  const { tokenListMap, fromRegistryTokenListMap, getTokenInfo } = useTokens(customTokens)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const trans = useTrans()

  const token = useMemo(
    () =>
      customToken ||
      (disableFallbackToSwapToken ? undefined : nickName === 'A' ? tokenIn : tokenOut),
    [nickName, customToken, tokenIn, tokenOut, disableFallbackToSwapToken],
  )

  const allTokens = useMemo(() => {
    if (customTokens) return customTokens

    const computedTokenList = new Map([
      ...(allowCustomMintAddress ? fromRegistryTokenListMap || [] : []),
      ...(tokenListMap || []),
    ])
    return Array.from(computedTokenList.values())
  }, [tokenListMap, allowCustomMintAddress, fromRegistryTokenListMap, customTokens])

  const availableTokens = useMemo(() => {
    return allTokens
      .sort((tokenA, tokenB) => tokenOrder[tokenB.symbol] - tokenOrder[tokenA.symbol])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideSelected, blackList, tokenIn, tokenOut, allTokens, nickName])

  const hasDropdown = useMemo(
    () => allowCustomMintAddress || (availableTokens.length > 0 && !disableDropdown),
    [allowCustomMintAddress, availableTokens, disableDropdown],
  )

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }, [anchorEl])

  const handleSelect = useCallback(
    (value: SwapTokenInfo) => {
      // TODO: call set function from context
      if (setCustomToken) {
        setCustomToken?.(value)
      }

      if (
        (nickName === 'A' && tokenOut?.address === value.address) ||
        (nickName !== 'A' && tokenIn?.address === value.address)
      ) {
        switchMintAddresses()
      } else {
        nickName === 'A' ? setInputMintAddress(value.address) : setOutputMintAddress(value.address)
      }

      handleClose()
    },
    [
      switchMintAddresses,
      setCustomToken,
      nickName,
      setInputMintAddress,
      setOutputMintAddress,
      handleClose,
      tokenIn,
      tokenOut,
    ],
  )

  const open = Boolean(anchorEl)
  const id = open ? 'token-picker' : undefined

  return (
    <>
      <Box
        data-testid={props['data-testid']}
        onClick={hasDropdown ? handleClick : () => null}
        className={hasDropdown ? 'cursor-pointer' : ''}
        bgcolor="#F0F0F5"
        borderRadius={80}
        paddingX={1.5}
        paddingY={1}
        {...props.boxProps}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="shrink-0 grow-0"
        >
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
            {token && (
              <Box className="w-[28px]">
                <Image
                  src={staticImportIcon(`fiat-${token.name.toLowerCase()}`)}
                  alt={token.name}
                  className="max-w-full rounded-full w-[28px] h-[28px] object-cover"
                />
              </Box>
            )}
            <Typography
              data-testid={`token-${nickName.toLowerCase()}-display-name`}
              className={`text-base ${
                token ? '' : 'italic opacity-60'
              } select-none text-[#29292E] font-semibold not-italic`}
            >
              {token ? token.name : trans.common.token_picker_please_select}
            </Typography>
          </Stack>
          {hasDropdown && <ExpandMoreIcon sx={{ color: '#29292E' }} />}
        </Stack>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder={trans.common.token_picker_filter_by}
                  required
                />
              )}
              onClose={(_event: ChangeEvent<object>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              options={[...availableTokens]}
              value={token}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }
                if (newValue) {
                  handleSelect(newValue)
                }
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={trans.common.token_picker_no_tokens_found}
              renderOption={(props, option, { selected }) => {
                return (
                  <li
                    {...props}
                    key={option.address}
                    data-testid={`token-picker-${nickName.toLowerCase()}-${option.symbol}`}
                  >
                    <Box className="flex items-center w-full gap-x-2">
                      <Box className="w-[30px] min-w-[30px]">
                        <Image
                          src={staticImportIcon(`fiat-${option.name.toLowerCase()}`)}
                          alt={option.name}
                          className="max-w-full rounded-full w-[30px] h-[30px] object-cover"
                        />
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          minWidth: 0,
                          '& .token-name': {
                            // color: theme.palette.mode === 'light' ? '#29292E' : '#29292E',
                          },
                        }}
                      >
                        <span className="flex items-center justify-center gap-x-[5px]">
                          <span
                            className="min-w-0 text-sm font-semibold truncate token-name"
                            title={option.name}
                          >
                            {option.name}
                          </span>
                        </span>
                      </Box>
                    </Box>
                  </li>
                )
              }}
              getOptionLabel={(option) => option.name}
              filterOptions={(options, state) => {
                const searchValue = state.inputValue.toLowerCase().trim()
                const matchesExact: TokenInfo[] = []
                const matchesByPrefix: TokenInfo[] = []

                options.forEach((option) => {
                  const setOfElements = [option.name]
                  const isExactMatch = setOfElements.some(
                    (item) => item.toLowerCase() === searchValue,
                  )
                  const isPrefixMatch = setOfElements.some((item) =>
                    item.toLowerCase().startsWith(searchValue),
                  )

                  if (isExactMatch) matchesExact.push(option)
                  else if (isPrefixMatch) matchesByPrefix.push(option)
                })

                // Only add the custom option if there is no whole match!
                const matchesCustom =
                  allowCustomMintAddress && state.inputValue && !matchesExact.length
                    ? [getTokenInfo(state.inputValue)]
                    : []

                return [...matchesExact, ...matchesByPrefix, ...matchesCustom]
              }}
              isOptionEqualToValue={(option, value) =>
                [option, tokenIn, tokenOut].some((item) => item === value)
              }
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default TokenPicker
