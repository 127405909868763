import { MouseEvent, useCallback } from 'react'
import { Stack, Typography, IconButton, Skeleton } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import type { SwapTokenInfo } from '@/hooks/use-tokens'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { currencyPriorities } from '@/constants/token'

import Image from 'next/image'
import useTrans from '@/hooks/useTrans'

interface RateProps {
  tokenIn: SwapTokenInfo
  tokenOut: SwapTokenInfo
  exchangeRate: string
  handleSwitchMintAddresses: (e: MouseEvent<HTMLButtonElement>) => void
  handleRefreshRateClick: (e: MouseEvent<HTMLButtonElement>) => void
  isLoadingRate: boolean
}

function Rate(props: RateProps) {
  const {
    tokenIn,
    tokenOut,
    exchangeRate,
    isLoadingRate,
    handleSwitchMintAddresses,
    handleRefreshRateClick,
  } = props
  const trans = useTrans()

  const getTokenRateOrder = useCallback((tokenIn: SwapTokenInfo, tokenOut: SwapTokenInfo) => {
    for (const tokenName of currencyPriorities) {
      if (tokenIn.name === tokenName) {
        return [tokenIn.name, tokenOut.name]
      }
      if (tokenOut.name === tokenName) {
        return [tokenOut.name, tokenIn.name]
      }
    }
  }, [])

  const [ firstTokenName, lastTokenName ] = getTokenRateOrder(tokenIn, tokenOut) || [tokenIn.name, tokenOut.name]

  return (
    <Stack direction="row" justifyContent="space-between" className="w-full pt-6">
      <IconButton
        color="secondary"
        className="bg-iw-primary hover:bg-iw-primary-dark w-11 h-11"
        onClick={handleSwitchMintAddresses}
      >
        <Image src={staticImportIcon(IconName.SWAP)} alt={IconName.SWAP} />
      </IconButton>

      <Stack className="flex-1 text-right text-iw-dark-gray" alignItems="end">
        {isLoadingRate || exchangeRate === '-' || exchangeRate === '0' ? (
          <>
            <Skeleton variant="text" width={95} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={137} sx={{ fontSize: '1rem' }} />
          </>
        ) : (
          <>
            {exchangeRate !== '-' && (
              <>
                <Typography>{trans.instantwire_transaction.draft_transaction.rate}</Typography>
                <Typography className="text-iw-neutral-black" variant="body1" fontWeight="bold">
                  {`${1} ${firstTokenName}`}
                  &nbsp;≈&nbsp;
                  <NumericFormat
                    value={exchangeRate}
                    decimalScale={tokenOut.decimals}
                    thousandSeparator
                    valueIsNumericString
                    displayType="text"
                  />
                  &nbsp;{lastTokenName}
                </Typography>
              </>
            )}
          </>
        )}
      </Stack>

      <IconButton data-testid="exchange-rate-button" className="ml-2 mr-2" onClick={handleRefreshRateClick} disabled={isLoadingRate}>
        <Image src={staticImportIcon(IconName.REFRESH)} alt={IconName.REFRESH} />
      </IconButton>
    </Stack>
  )
}

export default Rate
