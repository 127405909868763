import { memo } from 'react'

import CustomNumericFormat from '@/components/base/CustomNumericFormat'
import { useEventCallback } from '@/utils/use-event-callback'
import { type InputBaseProps } from '@mui/material'
import InputBase from '@mui/material/InputBase'

export type BaseAmountInputProps = InputBaseProps & {
  decimals?: number,
}

const BaseAmountInput = ({ inputProps, decimals = 9, ...props }: BaseAmountInputProps) => {
  const onInputFocus = useEventCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (Number(event.target.value) === 0) {
      event.target.select()
    }
  })

  return (
    <InputBase
      inputComponent={CustomNumericFormat as any}
      placeholder="0"
      autoComplete="off"
      inputProps={{
        'aria-label': '0',
        className: 'p-0 text-2xl text-right text-[#0F0E0C]',
        min: 0,
        ...(inputProps || {}),
        decimalScale: decimals,
        allowNegative: false,
        thousandSeparator: ',',
      }}
      onFocus={onInputFocus}
      {...props}
    />
  )
}

export default memo(BaseAmountInput)
