import { PublicKey, PublicKeyInitData } from '@solana/web3.js'
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from 'react-query'

import Decimal from 'decimal.js'
import { customTokens } from '@/constants/token'
import {
  FIAT_USD,
  FIAT_VND,
  REUSD,
  REVND,
} from '@/hooks/use-tokens'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import {
  Whirlpool,
} from '@renec-foundation/nemoswap-sdk'
import { TokenInfo } from '@renec-foundation/rpl-token-registry'
import { TRANSACTION_FEE_RATIO } from '../constants'
import { fetchExchangeRate, fetchTokens } from '@/utils/apis/exchange-rate'

import { findOptimalRoute, SwapOption } from '@renec-foundation/swap-router-sdk'
import useNemoProgram from '@/hooks/use-nemo-program'
import { useDebounce } from 'usehooks-ts'
import { currencyPriorities } from '@/constants/token'
import { exchangeRates as fixedExchangeRates } from '@/constants/exchange_rates'

export type PersonalBankInformation = {
  bankAccountNumber: string
  bankName: string
  bankAccountName: string
  email: string
  phoneNumber: string
}

export type WhirlpoolWithTokenAmountInfo = Whirlpool & {
  tokensAmount?: Record<string, Decimal>
  totalTokensValue?: Decimal
}

export type SwapContextType = {
  inputMintAddress: string
  setInputMintAddress: (mintAddress: string) => void
  outputMintAddress: string
  setOutputMintAddress: (mintAddress: string) => void
  setMintAddresses: (s: [string, string]) => void
  switchMintAddresses: () => void
  refreshRate: () => void
  tokenIn: TokenInfo
  tokenOut: TokenInfo
  exchangeRate: string
  displayExchangeRate: string
  fee?: string
  feeBeforeDiscount?: string
  isLoading: boolean
  inputAmount: string
  outputAmount: string
  outputAmountInCurrencyIn: string,
  setInputAmount: (amount: string) => void
  setOutputAmount: (amount: string) => void
  setBankInformation: (personalBank: PersonalBankInformation) => void
  bankInformation: PersonalBankInformation
  step: number
  changeStep: (step: number) => void
}

const FIAT_USD_OBJ: TokenInfo = {
  chainId: 101,
  address: FIAT_USD.address,
  name: FIAT_USD.name,
  decimals: 9,
  symbol: FIAT_USD.symbol,
  logoURI: FIAT_USD.logoURI,
}

const FIAT_VND_OBJ: TokenInfo = {
  chainId: 101,
  address: FIAT_VND.address,
  name: FIAT_VND.name,
  decimals: 9,
  symbol: FIAT_VND.symbol,
  logoURI: FIAT_VND.logoURI,
}

const PRECISE_DECIMALS = 18
const INIT_RATE_DECIMALS = 0
const EXCHANGE_RATE_DECIMALS = 2

const initContextState: SwapContextType = {
  inputMintAddress: REUSD,
  setInputMintAddress: () => null,
  outputMintAddress: REVND,
  setOutputMintAddress: () => null,
  switchMintAddresses: () => null,
  setMintAddresses: () => null,
  refreshRate: () => null,
  tokenIn: customTokens.find(token => token.name === FIAT_USD.name) || FIAT_USD_OBJ,
  tokenOut: customTokens.find(token => token.name === FIAT_VND.name) || FIAT_VND_OBJ,
  exchangeRate: '-',
  displayExchangeRate: '-',
  isLoading: false,
  inputAmount: '',
  outputAmount: '',
  outputAmountInCurrencyIn: '',
  setInputAmount: () => null,
  setOutputAmount: () => null,
  bankInformation: {
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    email: '',
    phoneNumber: '',
  },
  setBankInformation: () => null,
  step: 0,
  changeStep: () => null,
}

const SwapContext = createContext<SwapContextType>(initContextState)

export const SwapProvider = ({ children }: HocProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [mintAddresses, setMintAddresses] = useState<[string, string]>([
    initContextState.tokenIn.address,
    initContextState.tokenOut.address,
  ])
  const [inputMintAddress, outputMintAddress] = useMemo(() => mintAddresses, [mintAddresses])
  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmountState] = useState('')
  const [bankInformation, setBankInformation] = useState<PersonalBankInformation>({
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    email: '',
    phoneNumber: '',
  })
  const [step, changeStep] = useState(0)
  const [exchangeRate, setExchangeRate] = useState<string>('init')
  const [displayExchangeRate, setDisplayExchangeRate] = useState<string>('init')
  const [fee, setFee] = useState<string>('0')
  const [feeBeforeDiscount, setFeeBeforeDiscount] = useState<string>('0')
  const [outputAmountInCurrencyIn, setOutputAmountInCurrencyIn] = useState<string>('')
  const [lastRefreshedRateAt, setLastRefreshedRateAt] = useState<Date>(new Date())

  const transferDataRef = useRef('VND_USD_0')
  const debouncedInputAmount = useDebounce(inputAmount, 500)

  const tokenIn = useMemo(() => {
    return customTokens.find((token) => token.address === inputMintAddress) || FIAT_USD_OBJ
  }, [inputMintAddress])

  const tokenOut = useMemo(() => {
    return customTokens.find((token) => token.address === outputMintAddress) || FIAT_VND_OBJ
  }, [outputMintAddress])

  useEffect(() => {
    if (debouncedInputAmount !== '' && debouncedInputAmount !== '0') setIsLoading(true)

    transferDataRef.current = `${tokenIn.name}_${tokenOut.name}_${inputAmount}`
  }, [debouncedInputAmount, inputAmount, tokenIn, tokenOut])

  const setDefaultRate = useCallback(async () => {
    const pairOfTokensKey = `${tokenIn.name}${tokenOut.name}`

    switch (pairOfTokensKey) {
      case 'USDVND':
      case 'VNDUSD':
        const [reVNDPrice] = await fetchExchangeRate({ currency: 'VND' })
        setDisplayExchangeRate(new Decimal(reVNDPrice).toFixed(INIT_RATE_DECIMALS).toString())
        break
      case 'NGNUSD':
      case 'USDNGN':
        const [reNGNPrice] = await fetchExchangeRate({ currency: 'NGN' })
        setDisplayExchangeRate(new Decimal(reNGNPrice).toFixed(INIT_RATE_DECIMALS).toString())
        break
      default:
        const fixedExchangeRate = fixedExchangeRates[`${tokenIn.name}${tokenOut.name}`]
        setDisplayExchangeRate(fixedExchangeRate)
    }

  }, [tokenIn.name, tokenOut.name])

  useEffect(() => {
    if (debouncedInputAmount === '' || debouncedInputAmount === '0') {
      setDefaultRate()
    }
  }, [setDefaultRate, debouncedInputAmount])

  const switchMintAddresses = useCallback(() => {
    setMintAddresses([outputMintAddress, inputMintAddress])
  }, [inputMintAddress, outputMintAddress])

  const setInputMintAddress = useCallback(
    (mintAddress: string) => {
      setMintAddresses([mintAddress, outputMintAddress])
    },
    [outputMintAddress],
  )

  const setOutputAmount = useCallback(
    (amount: string) => {
      setOutputAmountState(amount)
    },
    [setOutputAmountState],
  )

  const setOutputMintAddress = useCallback(
    (mintAddress: string) => {
      setMintAddresses([inputMintAddress, mintAddress])
    },
    [inputMintAddress],
  )

  useEffect(() => {
    if (debouncedInputAmount === '' || debouncedInputAmount === '0') return

    const inputAmountInDecimal = new Decimal(debouncedInputAmount)
    setFeeBeforeDiscount(inputAmountInDecimal.mul(TRANSACTION_FEE_RATIO).toFixed(PRECISE_DECIMALS))
    setFee('0')
    setOutputAmountInCurrencyIn(inputAmountInDecimal.toFixed(PRECISE_DECIMALS))
  }, [debouncedInputAmount])

  const { data: pools } = useQuery({
    queryKey: ['fetchPools', 'reVND', 'reUSD', 'reNGN'],
    queryFn: async () => await fetchTokens(),
    refetchOnWindowFocus: false,
  })

  const { client } = useNemoProgram()
  const { data: featureFlags } = useFeatureFlags()

  const updateOutputAmount = useCallback(async (inputAmountInDecimal: Decimal, refresh?: boolean) => {
    if (pools) {
      const poolList = pools.map((pool: { pool_address: PublicKeyInitData; token_a_mint_address: PublicKeyInitData; token_b_mint_address: PublicKeyInitData }) => {
        return {
          poolAddress: new PublicKey(pool.pool_address),
          tokenAMintAddress: new PublicKey(pool.token_a_mint_address),
          tokenBMintAddress: new PublicKey(pool.token_b_mint_address),
        }
      })

      const route = await findOptimalRoute({
        client,
        poolList,
        tokenAMintAddress: new PublicKey(inputMintAddress),
        tokenBMintAddress: new PublicKey(outputMintAddress),
        tokenAmount: inputAmountInDecimal,
        option: SwapOption.ExactIn,
        refresh,
      })
      const instantwireRate = Number(featureFlags?.currency_rate_percentage[`${tokenIn.name}${tokenOut.name}`])

      const safeOutputAmount = Decimal.mul(
        route?.estimatedOutputAmount || 0,
        Decimal.sub(
          1,
          Decimal.mul(instantwireRate || 0, 0.01),
        ),
      )
      const transferDataKey = `${tokenIn.name}_${tokenOut.name}_${debouncedInputAmount}`
      if (transferDataKey !== transferDataRef.current) return

      setOutputAmount(safeOutputAmount.toString())
      setExchangeRate(safeOutputAmount.div(inputAmount).toString())

      for (const tokenName of currencyPriorities) {
        if (tokenIn.name === tokenName) {
          setDisplayExchangeRate(safeOutputAmount.div(inputAmount).toFixed(EXCHANGE_RATE_DECIMALS).toString())
          break
        }
        if (tokenOut.name === tokenName) {
          setDisplayExchangeRate(inputAmountInDecimal.div(safeOutputAmount).toFixed(EXCHANGE_RATE_DECIMALS).toString())
          break
        }
      }
      setIsLoading(false)
    }
  }, [pools, client, inputMintAddress, outputMintAddress, featureFlags?.currency_rate_percentage, tokenIn.name, tokenOut.name, debouncedInputAmount, setOutputAmount, inputAmount])

  const computeOutputAmountAndExchangeRate = useCallback(async (refresh_onchain_data?: boolean) => {
    if (debouncedInputAmount === '' || debouncedInputAmount === '0') return

    setDisplayExchangeRate('-')
    setExchangeRate('-')
    const inputAmountInDecimal = new Decimal(debouncedInputAmount)
    updateOutputAmount(inputAmountInDecimal, refresh_onchain_data)
  }, [
    debouncedInputAmount,
    updateOutputAmount,
  ])

  useEffect(() => {
    computeOutputAmountAndExchangeRate()
  }, [computeOutputAmountAndExchangeRate])

  useEffect(() => {
    computeOutputAmountAndExchangeRate(true)
    const interval = setInterval(() => {
      setLastRefreshedRateAt(new Date())
    }, 20 * 1000)
    return () => clearInterval(interval)
  }, [lastRefreshedRateAt, computeOutputAmountAndExchangeRate])

  const refreshRate = useCallback(() => {
    computeOutputAmountAndExchangeRate(true)
  }, [computeOutputAmountAndExchangeRate])

  const contextValue = useMemo(() => {
    return {
      inputMintAddress,
      setInputMintAddress,
      outputMintAddress,
      setOutputMintAddress,
      switchMintAddresses,
      tokenIn,
      tokenOut,
      exchangeRate,
      displayExchangeRate,
      fee,
      feeBeforeDiscount,
      isLoading: isLoading,
      inputAmount,
      outputAmount,
      outputAmountInCurrencyIn,
      setInputAmount,
      setOutputAmount,
      setBankInformation,
      bankInformation,
      step,
      changeStep,
      refreshRate,
      setMintAddresses,
    }
  }, [
    inputAmount,
    outputAmount,
    outputAmountInCurrencyIn,
    setInputAmount,
    setOutputAmount,
    inputMintAddress,
    setInputMintAddress,
    outputMintAddress,
    setOutputMintAddress,
    switchMintAddresses,
    tokenIn,
    tokenOut,
    exchangeRate,
    displayExchangeRate,
    fee,
    feeBeforeDiscount,
    isLoading,
    setBankInformation,
    bankInformation,
    step,
    changeStep,
    refreshRate,
    setMintAddresses,
  ])

  return <SwapContext.Provider value={contextValue}>{children}</SwapContext.Provider>
}

SwapContext.displayName = 'SwapContext'
export default SwapContext
