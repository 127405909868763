import { useMemo } from 'react'
import { useExchangeRates } from './use-exchange-rates'
import { useFeatureFlags } from './use-feature-flags'
import { useUser } from './use-user'

const TRANSACTION_DISCOUNT_TIERS_VND = [10_000_000, 20_000_000]
const TRANSACTION_DISCOUNT_TIERS_USD = [500, 1_000]
const MAX_POINTS_EACH_TIER = [100, 200, Infinity]

type useCalculateDiscountAmountProps = {
  amount: number | string | undefined
  currency: string | undefined
}

const useCalculateDiscountAmount = (props: useCalculateDiscountAmountProps) => {
  const { currency } = props
  const amount = Number(props.amount)
  const { data: currentUser } = useUser()
  const { data: ratesData } = useExchangeRates({ currency: 'VND' })
  const [reUSDPrice, reVNDPrice] = ratesData || [0, 0]
  const { data: featureFlags } = useFeatureFlags()

  const wirePointsToUSD = featureFlags?.wirepoint_to_usd_rate

  const discountTier = useMemo(() => {
    const transaction_discount_tiers =
      currency === 'VND' ?
        TRANSACTION_DISCOUNT_TIERS_VND :
        TRANSACTION_DISCOUNT_TIERS_USD

    const discountTierAmount = transaction_discount_tiers.find((tierCap) => amount <= tierCap)
    return discountTierAmount ?
      transaction_discount_tiers.indexOf(discountTierAmount) :
      transaction_discount_tiers.length
  }, [amount, currency])

  const discountWirePoints = useMemo(() => {
    if (!currentUser) return 0

    const max_points_limit_by_amount = Math.floor(amount /
      (currency === 'VND' ? wirePointsToUSD * reUSDPrice : wirePointsToUSD))

    const max_convertable_points = Math.min(currentUser.wirePoints, max_points_limit_by_amount)

    return Math.min(MAX_POINTS_EACH_TIER[discountTier], max_convertable_points)
  }, [amount, currency, currentUser, wirePointsToUSD])

  const discountAmount = useMemo(() => {
    const discountAmountInUsd = discountWirePoints * wirePointsToUSD
    if (currency === 'VND') {
      // 1 point === $0.05
      return discountAmountInUsd * reUSDPrice
    } else {
      return discountAmountInUsd
    }
  }, [discountWirePoints, reUSDPrice, reVNDPrice, currency, amount, wirePointsToUSD])

  return { discountAmount, discountWirePoints, discountTier }
}

export default useCalculateDiscountAmount
