import * as amplitude from '@amplitude/analytics-browser'

let amplitudeInitialized = false
const DEFAULT_EVENT_PROPS = { product: 'instantwire' }
const clientInstance = amplitude.createInstance()

const initializeAmplitude = async () => {
  if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
    await clientInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, {
      defaultTracking: {
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
      },
    }).promise
  }
  amplitudeInitialized = true
}

export const track = async (eventName: string, data?: any) => {
  try {
    if (!amplitudeInitialized) await initializeAmplitude()
    clientInstance.track(eventName, { ...DEFAULT_EVENT_PROPS, ...data })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
