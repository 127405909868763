import { api } from '.'

export const fetchExchangeRate = async ({ currency: inputCurrency }: { currency: string }):
  Promise<[number, number]> => {
  const { data } = await api.get('/instantwire/settings/exchange_rates', {
    params: { token_name: inputCurrency },
    transformResponse: (raw_data: any, _header: any) => {
      const data = JSON.parse(raw_data)

      const [reUSDPrice, inputCurrencyPrice] = data.rate
      return [reUSDPrice, inputCurrencyPrice]
    },
  })
  return data || [0, 0]
}

export const fetchTokens = async ():
  Promise<any> => {
  const { data } = await api.get('/tokens?statuses%5B%5D=success&only_pool_related=true&v=dummy1&symbols[]=reVND&symbols[]=reUSD&symbols[]=reNGN', {
    transformResponse: (raw_data: any, _header: any) => {
      const data = JSON.parse(raw_data)

      const pools = (data['items'].map((token: { [attr: string]: any }) => {
        return token['pools']
      })).flat()
      return pools
    },
  })

  return data
}
