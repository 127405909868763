import { useQuery } from 'react-query'
import { fetchExchangeRate } from '@/utils/apis/exchange-rate'

export const useExchangeRates = ({ currency }: { currency: string }) => useQuery(
  ['fetchExchangeRates', currency], () => fetchExchangeRate({ currency }),
  {
    refetchOnWindowFocus: false,
    cacheTime: 30 * 1000,
    staleTime: 30 * 1000,
    enabled: currency !== undefined,
  },
)

